import React from "react"
import { Link } from "gatsby"

const PubCategories = () => {
  return (
    <div className="publication-categories mt-3">
      <Link
        to="/publicaciones"
        className="block lg:inline-block text-sm mr-3 mb-2 px-3 py-2 bg-gray-200 rounded outline-none no-underline"
        activeClassName="bg-gray-700 text-white"
      >
        Todas
      </Link>

      <Link
        to="/publicaciones-video"
        className="block lg:inline-block text-sm mr-3 mb-2 px-3 py-2 bg-gray-200 rounded outline-none no-underline"
        activeClassName="bg-gray-700 text-white"
      >
        Videoteca
      </Link>

      <Link
        to="/publicaciones-revistas"
        className="block lg:inline-block text-sm mr-3 mb-2 px-3 py-2 bg-gray-200 rounded outline-none no-underline"
        activeClassName="bg-gray-700 text-white"
      >
        Revistas
      </Link>

      <Link
        to="/publicaciones-prensa"
        className="block lg:inline-block text-sm mr-3 mb-2 px-3 py-2 bg-gray-200 rounded outline-none no-underline"
        activeClassName="bg-gray-700 text-white"
      >
        Prensa
      </Link>

      <Link
        to="/publicaciones-investigacion"
        className="block lg:inline-block text-sm mr-3 mb-2 px-3 py-2 bg-gray-200 rounded outline-none no-underline"
        activeClassName="bg-gray-700 text-white"
      >
        Investigaciones
      </Link>
      <Link
        to="/publicaciones-libros"
        className="block lg:inline-block text-sm mr-3 mb-2 px-3 py-2 bg-gray-200 rounded outline-none no-underline"
        activeClassName="bg-gray-700 text-white"
      >
        Libros
      </Link>
    </div>
  )
}

export default PubCategories
